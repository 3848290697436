// 
// Schedule listing for events
// example can be seen at index-event.html
// 

.schedule-date {
  line-height: 38px;
  position: relative;
  margin-bottom: 20px;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 110%;
    margin: auto;
    bottom: -20px;
    left: -5px;
    right: 0;
    background: gray("300");

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}

.schedule-event {
  padding-top: 1.75rem;
  width: 100%;
  display: flex;
}

.schedule-time {
  display: inline-block;
  width: 40%;
  position: relative;
  text-align: center;
  padding-left: .5rem;
  padding-right: .5rem;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: -1.75rem;
    width: 1px;
    height: 230%;
    background: gray("300");
  }

  &:after {
    content: '';
    width: 11px;
    height: 11px;
    border: 1px solid theme-color("purple");
    background: theme-color("light");
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 7px;
    right: -5px;
  }
}

.schedule-title {
  width: 60%;
  display: inline-block;
  padding-left: 2rem;
}


// 
// Schedule for RTL
// 

body.rtl {
  .schedule-time {
    &:before {
      right: initial;
      left: 0;
    }

    &:after {
      right: initial;
      left: -5px;
    }
  }
}

//
// Responsive borders
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if($infix != "") {
      .border#{$infix}          { border: $border-width solid $border-color !important; }
      .border#{$infix}-top      { border-top: $border-width solid $border-color !important; }
      .border#{$infix}-right    { border-right: $border-width solid $border-color !important; }
      .border#{$infix}-bottom   { border-bottom: $border-width solid $border-color !important; }
      .border#{$infix}-left     { border-left: $border-width solid $border-color !important; }

      .border#{$infix}-0        { border: 0 !important; }
      .border#{$infix}-top-0    { border-top: 0 !important; }
      .border#{$infix}-right-0  { border-right: 0 !important; }
      .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
      .border#{$infix}-left-0   { border-left: 0 !important; }
    }
  }
}

// Gray borders

@each $key, $value in $grays {
  .border-gray-#{$key} {
    border-color: $value !important;
  }
}

.border-2 { border-width: 2px !important; }

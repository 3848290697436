// 
// Responsive fixed widths and heights 
// 

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size in $fixed-sizes {
      .w#{$infix}-#{$size} { width: #{$size}px !important; }
      .h#{$infix}-#{$size} { height: #{$size}px !important; }
    }
  }
}


// 
// Responsive sizing
// 

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // responsive viewports: (vw-20, vw-sm-20, vw-md-20...)
    @each $size, $length in $sizes {
      @if is-integer($size) {
        .vw#{$infix}-#{$size} { width: #{$size}vw !important; }
        .vh#{$infix}-#{$size} { height: #{$size}vh !important; }

        .min-vw#{$infix}-#{$size} { min-width: #{$size}vw !important; }
        .min-vh#{$infix}-#{$size} { min-height: #{$size}vh !important; }
      }
    }

    // responsive width and height 
    // (w-sm-30, w-md-30, w-lg-30...)
    // (h-sm-50, h-md-50, h-lg-50...)
    @each $prop, $abbrev in (width: w, height: h) {
      @if($infix != "") {
        @each $size, $length in $sizes {
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        }
      }
    }
  }
}
